/* Reset */
* {
  box-sizing: border-box;
}

body {
  padding-bottom: 100px;
  margin: 0;
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: #f5f5f5;
}

h1 {
  text-align: center;
  margin-bottom: 50px;
}

a {
  color: #2980b9;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

/* Hero Section */
.hero {
  background: linear-gradient(135deg, #3498db, #2ecc71);
  color: white;
  padding: 60px 20px;
  text-align: center;
}

.hero-content {
  max-width: 800px;
  margin: 0 auto;
}

.hero h1 {
  font-size: 2.5rem;
  margin-bottom: 10px;
  font-family: 'Playfair Display', serif;
}

.hero p {
  font-size: 1.2rem;
  margin-bottom: 30px;
  font-family: 'Ubuntu', sans-serif;
}

.social-icons {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.social-icons a {
  color: white;
  font-size: 1.8rem;
  transition: transform 0.2s, color 0.3s;
}

.social-icons a:hover {
  transform: scale(1.2);
  color: #f1c40f;
}

/* Timeline */
.timeline {
  position: relative;
  margin: 50px auto;
  width: 90%;
  max-width: 800px;
  padding: 20px 0;
}

.timeline-year {
  display: inline-block;
  margin: 20px auto;
  padding: 8px 16px;
  font-size: 1rem;
  font-weight: bold;
  color: #333;
  background-color: #fff;
  border: 0px solid #ccc;
  border-radius: 20px;
  box-shadow: 0 0 5px rgba(0,0,0,0.1);
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}


.timeline::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  width: 4px;
  background: #3498db;
  transform: translateX(-50%);
}

.timeline-item {
  position: relative;
  width: 50%;
  padding: 20px;
  box-sizing: border-box;
  opacity: 0;
  transition: all 0.6s ease-in-out;
}

.timeline-item.left {
  left: 0;
  text-align: right;
  transform: translateX(-40px);
}

.timeline-item.right {
  left: 50%;
  text-align: left;
  transform: translateX(40px);
}

.timeline-item.show {
  opacity: 1;
  transform: translateX(0);
}

/* Card Content */
.timeline-item .content {
  background-color: white;
  padding: 20px;
  position: relative;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.timeline-item .content h3,
.timeline-item .content h4,
.timeline-item .content p,
.timeline-item .content ul {
  margin: 10px 0;
  color: #333;
}

.timeline-item .content ul {
  padding-left: 20px;
}

.timeline-item .content li {
  margin-bottom: 5px;
}
.timeline-details {
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: max-height 0.8s ease-in-out, opacity 0.6s ease-in-out;
}

.timeline-details.open {
  max-height: 1000px; /* pode aumentar caso seu conteúdo seja maior */
  opacity: 1;
}


.timeline-header {
  display: flex;
  align-items: center;
}

/* Card da direita: logo vai à direita */
.timeline-item.right .timeline-header {
  flex-direction: row;
  justify-content: space-between;
}

.timeline-item.right .timeline-logo {
  margin-left: 10px;
}

/* Card da esquerda: logo vai à esquerda */
.timeline-item.left .timeline-header {
  flex-direction: row-reverse;
  justify-content: space-between;
}

.timeline-item.left .timeline-logo {
  margin-right: 10px;
}


.timeline-logo {
  width: 80px;
  height: auto;
  margin-left: 10px;
}

.timeline-title {
  cursor: pointer;
  color: #007bff;
}

.timeline-title:hover {
  text-decoration: underline;
}

.timeline-month {
  display: block;
  font-size: 0.9rem;
  font-weight: bold;
  color: #555;
  margin-bottom: 5px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.timeline-company {
  font-size: 1.2rem;
  margin: 5px 0;
  color: #2c3e50;
}

.timeline-role {
  font-size: 1rem;
  font-weight: normal;
  margin: 5px 0 10px;
  color: #555;
}

.timeline-more {
  color: #007bff;
  cursor: pointer;
  margin: 10px 0;
  display: inline-block;
}

.timeline-more:hover {
  text-decoration: underline;
}

.language-switcher {
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
  padding: 10px 20px;
  font-size: 14px;
}

.language-switcher button {
  display: flex;
  align-items: center;
  gap: 6px;
  background: none;
  border: none;
  padding: 6px 8px;
  color: #666;
  font-size: 14px;
  cursor: pointer;
  transition: color 0.2s ease;
}

.language-switcher button img {
  width: 18px;
  height: auto;
  border-radius: 3px;
}

.language-switcher button:hover {
  color: #333;
}

.language-switcher button:focus {
  outline: none;
}


/* Responsividade */
@media (max-width: 768px) {
  .timeline::before {
    left: 8px;
  }

  .timeline-item {
    width: 100%;
    padding-left: 30px;
    padding-right: 10px;
    text-align: left !important;
  }

  .timeline-item.left,
  .timeline-item.right {
    left: 0;
    transform: translateX(0);
  }

 /* Este trecho abaixo garante que os logos fiquem à direita no mobile */
 .timeline-item .timeline-header {
  flex-direction: row !important;
  justify-content: space-between;
}

.timeline-item .timeline-logo {
  margin-left: 10px;
  margin-right: 0;
}

.timeline-year {
  left: 0;
  transform: none;
  margin-left: 30px;
  text-align: left;
}
}
